export const transformPhoneNumber = ({ customerPhone, countryCode }) => {
  if ((countryCode === "GB" && customerPhone.split(" ").join(" ").length > 7) || customerPhone.length > 1) {
    if (countryCode === "GB" && customerPhone.slice(0, 3) !== "+44") {
      return customerPhone[0] === "0"
        ? `+44${customerPhone.slice(1)}`
        : `+44${customerPhone}`
    }

    return `+${customerPhone}`.replace("++", "+");
  }

  return null;
}

export const formatPhoneNumber = (phone) => {
  if (!phone) {
    return phone;
  }
 
  let cleaned = phone.replace(/[^\d+]/g, "");
  const length = cleaned.length;

  if (!cleaned.startsWith("+")) {
    cleaned = `+${cleaned}`;
  }

  if (length <= 6) {
    return cleaned;
  }
  
  return `${cleaned.slice(0, length - 6)} ${cleaned.slice(length - 6)}`;
};
