import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const TopBarLink = ({
  category,
  setSelectedTopBarCategory,
}) => {
  const handleMouseEnter = () => {
    setSelectedTopBarCategory(category);
  };

  const renderCategory = () => {
    if (category.isNew) {
      return <p>{category.title} <span className="header--link--new">NEW</span></p>
    }

    return category.title
  }

  return (
    <div className="header--top-bar-category-container">
      {["All Antiques", "Offered for sale"].includes(category.title) ? (
        <NavLink
          to={category.url}
          className="header--all-antiques-link"
          onMouseEnter={handleMouseEnter}
        >
          {renderCategory()}
        </NavLink>
      ) : (
        <a
          href={category.url}
          className="header--link"
          onClick={e => e.preventDefault()}
          onMouseEnter={handleMouseEnter}
        >
          {renderCategory()}
        </a>
      )}
    </div>
  )
};

TopBarLink.propTypes = {
  category: PropTypes.object.isRequired,
  setSelectedTopBarCategory: PropTypes.func.isRequired,
};

export default TopBarLink;
