import { convertBase64ToFile } from "./file";
import { DEFAULT_LOGO_SETTINGS } from "../constants";

export const generateFavicon = (data) => {
  const canvas = document.createElement("CANVAS");
  canvas.width = 48;
  canvas.height = 48;

  const context = canvas.getContext("2d");
  context.beginPath();
  context.moveTo(data.borderRadius, 0);
  context.arcTo(48, 0, 48, 48, data.borderRadius);
  context.arcTo(48, 48, 0, 48, data.borderRadius);
  context.arcTo(0, 48, 0, 0, data.borderRadius);
  context.arcTo(0, 0, 48, 0, data.borderRadius);
  context.fillStyle = data.backgroundColor;
  context.closePath();
  context.fill();

  context.fillStyle = data.color;
  context.textAlign = "center";
  context.textBaseline = "middle";

  context.font = `${data.fontWeight} ${data.fontSize} ${data.fontFamily}`;
  context.fillText(data.text, 24, 24);

  return convertBase64ToFile(canvas.toDataURL("image/png"), "favicon-builder-preview");
};

export const generateLogoIcon = (config = DEFAULT_LOGO_SETTINGS, autoDivideWord = false) => {
  const data = {
    ...config,
    style: {
      ...DEFAULT_LOGO_SETTINGS.style,
      ...config.style,
    },
  };

  const [fontSizeNumber] = data.style.fontSize.split("px");
  const canvas = document.createElement("CANVAS");

  canvas.width = 660;
  canvas.height = +fontSizeNumber + 200;

  const context = canvas.getContext("2d");

  context.fillStyle = "#FFFFFF";
  context.fillRect(0, 0, canvas.width, canvas.height);

  context.textAlign = "center";
  context.textBaseline = "middle";

  const wordsList = data.text.split(" ");

  if (autoDivideWord && wordsList.length > 3) {
    const firstLineText = wordsList.slice(0, wordsList.length / 2).join(" ");
    const secondLineText = wordsList.slice(wordsList.length / 2).join(" ");

    data.text = firstLineText;
    data.secondLineText = secondLineText;
  }

  context.font = `${data.style.fontWeight} ${fontSizeNumber * 3}px ${data.style.fontFamily}`;
  context.fillStyle = data.style.color;

  if (!data.secondLineText) {
    context.fillText(data.text, canvas.width / 2, canvas.height / 2);
  } else if (data.secondLineText) {
    context.fillText(data.text, canvas.width / 2, (canvas.height / 2) - ((fontSizeNumber * 3) / 2));
    context.fillText(data.secondLineText, canvas.width / 2, (canvas.height / 2) + (fontSizeNumber * 1.7));
  }

  return convertBase64ToFile(canvas.toDataURL("image/jpeg"), "dealer-custom-logo");
};
