export const buildFullName = (user, defaultValue = "") => {
  const firstName = user?.firstName?.trim() || "";
  const lastName = user?.lastName?.trim() || "";

  return [firstName, lastName].join(" ").trim() || defaultValue;
};

export const firstLetterCapitalize = (value = "") => {
  return value.substring(0, 1).toUpperCase() + value.substring(1).toLowerCase();
};

export const fromKebabCaseToNormal = (value = "") => {
  return value
    .replace(/-/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const fromSnakeCaseToNormal = (value = "") => {
  return value
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};
