import React from "react";


const IMAGES_FOLDER_URL = `${process.env.REACT_APP_IMAGES_URL}topBarCategories`;

export const TOP_BAR_CATEGORIES = [
  {
    title: "All Antiques",
    url: "/antiques",
    key: "antiques",
    id: 200,
  },
  {
    title: "Furniture",
    url: "/antique-furniture",
    key: "furniture",
    id: 201,
    categoryImage: `${IMAGES_FOLDER_URL}/Furniture/Furniture.jpg`,
    children: [
      {
        id: 1,
        title: "Beds",
        image: `${IMAGES_FOLDER_URL}/Beds/Beds.jpeg`,
        url: "/antique-beds"
      },
      {
        id: 2,
        title: "Bookcases",
        image: `${IMAGES_FOLDER_URL}/Bookcases/Bookcases.jpeg`,
        url: "/antique-bookcases"
      },
      {
        id: 3,
        title: "Cabinets",
        image: `${IMAGES_FOLDER_URL}/Cabinets/Cabinets.jpeg`,
        url: "/antique-cabinets"
      },
      {
        id: 4,
        title: "Chairs",
        image: `${IMAGES_FOLDER_URL}/Chairs/Chairs.jpeg`,
        url: "/antique-chairs"
      },
      {
        id: 5,
        title: "Chest of Drawers",
        image: `${IMAGES_FOLDER_URL}/Chest of Drawers/Chest of Drawers.jpeg`,
        url: "/antique-chest-of-drawers"
      },
      {
        id: 6,
        title: "Cupboards",
        image: `${IMAGES_FOLDER_URL}/Cupboards/Cupboards.jpeg`,
        url: "/antique-cupboards"
      },
      {
        id: 7,
        title: "Desks",
        image: `${IMAGES_FOLDER_URL}/Desks/Desks.jpeg`,
        url: "/antique-desks"
      },
      {
        id: 8,
        title: "Dressers",
        image: `${IMAGES_FOLDER_URL}/Dressers/Dressers.jpeg`,
        url: "/antique-dressers"
      },
      {
        id: 9,
        title: "Dressing Tables",
        image: `${IMAGES_FOLDER_URL}/Dressing Tables/Dressing Tables.jpeg`,
        url: "/antique-dressing-tables"
      },
      {
        id: 10,
        title: "Sideboards",
        image: `${IMAGES_FOLDER_URL}/Sideboards/Sideboards.jpeg`,
        url: "/antique-sideboards"
      },
      {
        id: 11,
        title: "Stools",
        image: `${IMAGES_FOLDER_URL}/Stools/Stools.jpeg`,
        url: "/antique-stools"
      },
      {
        id: 12,
        title: "Tables",
        image: `${IMAGES_FOLDER_URL}/Tables/Tables.jpeg`,
        url: "/antique-tables"
      },
      {
        id: 13,
        title: "Wardrobes",
        image: `${IMAGES_FOLDER_URL}/Wardrobes/Wardrobes.jpeg`,
        url: "/antique-wardrobes"
      }
    ]
  },
  {
    title: "Silver",
    url: "/antique-silver",
    key: "silver",
    id: 202,
    categoryImage: `${IMAGES_FOLDER_URL}/Silver/Silver.jpg`,
    children: [
      {
        id: 14,
        title: "Baskets",
        image: `${IMAGES_FOLDER_URL}/Silver/Silver Baskets.jpeg`,
        url: "/antique-silver/silver-baskets"
      },
      {
        id: 15,
        title: "Bowls",
        image: `${IMAGES_FOLDER_URL}/Silver/Silver Bowls.jpeg`,
        url: "/antique-silver/silver-bowls"
      },
      {
        id: 16,
        title: "Boxes",
        image: `${IMAGES_FOLDER_URL}/Silver/Silver Boxes.jpeg`,
        url: "/antique-silver/silver-boxes"
      },
      {
        id: 17,
        title: "Candlesticks",
        image: `${IMAGES_FOLDER_URL}/Silver/Silver Candlesticks.jpeg`,
        url: "/antique-silver/silver-candlesticks"
      },
      {
        id: 18,
        title: "Card Holders",
        image: `${IMAGES_FOLDER_URL}/Silver/Silver Card Holders.jpeg`,
        url: "/silver-card-holders"
      },
      {
        id: 19,
        title: "Coasters",
        image: `${IMAGES_FOLDER_URL}/Silver/Silver Coasters.jpeg`,
        url: "/antique-silver/silver-coasters"
      },
      {
        id: 20,
        title: "Coffee Pots",
        image: `${IMAGES_FOLDER_URL}/Silver/Silver Coffee Pots.jpeg`,
        url: "/antique-silver/silver-coffee-pots"
      },
      {
        id: 21,
        title: "Condiments",
        image: `${IMAGES_FOLDER_URL}/Silver/Silver Condiments.jpeg`,
        url: "/antique-silver/silver-condiments"
      },
      {
        id: 22,
        title: "Cutlery",
        image: `${IMAGES_FOLDER_URL}/Silver/Silver Cutlery.jpeg`,
        url: "/antique-silver/silver-cutlery"
      },
      {
        id: 23,
        title: "Decanter Labels",
        image: `${IMAGES_FOLDER_URL}/Silver/Silver Decanter Labels.jpeg`,
        url: "/antique-silver/silver-decanter-labels"
      },
      {
        id: 24,
        title: "Dishes",
        image: `${IMAGES_FOLDER_URL}/Silver/Silver Dishes.jpeg`,
        url: "/antique-silver/silver-dishes"
      },
      {
        id: 25,
        title: "Spoons",
        image: `${IMAGES_FOLDER_URL}/Silver/Silver Tea Caddy Spoon.jpeg`,
        url: "/antique-silver/silver-spoons"
      },
      {
        id: 26,
        title: "Vases",
        image: `${IMAGES_FOLDER_URL}/Silver/Silver Vases.jpeg`,
        url: "/antique-silver/silver-vases"
      },
    ]
  },
  {
    title: "Art",
    url: "/antique-art",
    key: "art",
    id: 203,
    categoryImage: `${IMAGES_FOLDER_URL}/Art/Art.jpg`,
    children: [
      {
        id: 27,
        title: "Cityscape Art",
        image: `${IMAGES_FOLDER_URL}/Art/Cityscape Art.jpeg`,
        url: "/antique-art/cityscape-art"
      },
      {
        id: 28,
        title: "Contemporary Art",
        image: `${IMAGES_FOLDER_URL}/Art/Contemporary Art.jpeg`,
        url: "/antique-art/contemporary-art"
      },
      {
        id: 29,
        title: "Drawings",
        image: `${IMAGES_FOLDER_URL}/Art/Drawings.jpeg`,
        url: "/antique-art/drawings"
      },
      {
        id: 30,
        title: "Easels",
        image: `${IMAGES_FOLDER_URL}/Art/Easels.jpeg`,
        url: "/antique-art/easels"
      },
      {
        id: 31,
        title: "Landscape Art",
        image: `${IMAGES_FOLDER_URL}/Art/Landscape Art.jpeg`,
        url: "/antique-art/landscape-art"
      },
      {
        id: 32,
        title: "Marinescape Art",
        image: `${IMAGES_FOLDER_URL}/Art/Marinescape Art.jpeg`,
        url: "/antique-art/marinescape-art"
      },
      {
        id: 33,
        title: "Miniatures",
        image: `${IMAGES_FOLDER_URL}/Art/Miniature Portraits.jpeg`,
        url: "/antique-art/miniatures"
      },
      {
        id: 34,
        title: "Oil Paintings",
        image: `${IMAGES_FOLDER_URL}/Art/Oil Paintings.jpeg`,
        url: "/antique-art/oil-paintings"
      },
      {
        id: 35,
        title: "Pastel",
        image: `${IMAGES_FOLDER_URL}/Art/Pastel.jpeg`,
        url: "/antique-art/pastel"
      },
      {
        id: 36,
        title: "Portraits",
        image: `${IMAGES_FOLDER_URL}/Art/Portraits.jpeg`,
        url: "/antique-art/portraits"
      },
      {
        id: 37,
        title: "Seascape Art",
        image: `${IMAGES_FOLDER_URL}/Art/Seascape Art.jpeg`,
        url: "/antique-art/seascape-art"
      },
      {
        id: 38,
        title: "Still Life",
        image: `${IMAGES_FOLDER_URL}/Art/Still Life Art.jpeg`,
        url: "/antique-art/still-life"
      },
      {
        id: 39,
        title: "Watercolours",
        image: `${IMAGES_FOLDER_URL}/Art/Watercolours.jpeg`,
        url: "/antique-art/watercolours"
      }
    ]
  },
  {
    title: "Jewellery",
    url: "/vintage-jewellery",
    key: "jewellery",
    id: 204,
    categoryImage: `${IMAGES_FOLDER_URL}/Jewellery/Jewellery.jpg`,
    children: [
      {
        id: 40,
        title: "Antique Bracelets & Bangles",
        image: `${IMAGES_FOLDER_URL}/Jewellery/Bracelets & Bangles.jpeg`,
        url: "/vintage-jewellery/bracelets-and-bangles"
      },
      {
        id: 41,
        title: "Brooches",
        image: `${IMAGES_FOLDER_URL}/Jewellery/Brooches.jpeg`,
        url: "/vintage-jewellery/brooches"
      },
      {
        id: 42,
        title: "Cufflinks",
        image: `${IMAGES_FOLDER_URL}/Jewellery/Cufflinks.jpeg`,
        url: "/vintage-jewellery/cufflinks"
      },
      {
        id: 43,
        title: "Diamond Rings",
        image: `${IMAGES_FOLDER_URL}/Jewellery/Diamond Rings.jpeg`,
        url: "/vintage-jewellery/diamond-rings"
      },
      {
        id: 44,
        title: "Earrings",
        image: `${IMAGES_FOLDER_URL}/Jewellery/Earrings.jpeg`,
        url: "/vintage-jewellery/earrings"
      },
      {
        id: 45,
        title: "Engagement & Wedding Rings",
        image: `${IMAGES_FOLDER_URL}/Jewellery/Engagement & Wedding Rings.jpeg`,
        url: "/vintage-jewellery/engagement-and-wedding-rings"
      },
      {
        id: 46,
        title: "Jewellery Boxes",
        image: `${IMAGES_FOLDER_URL}/Jewellery/Jewellery Boxes.jpeg`,
        url: "/vintage-jewellery/vintage-jewellery-boxes"
      },
      {
        id: 47,
        title: "Lockets",
        image: `${IMAGES_FOLDER_URL}/Jewellery/Lockets.jpeg`,
        url: "/vintage-jewellery/lockets"
      },
      {
        id: 48,
        title: "Necklaces",
        image: `${IMAGES_FOLDER_URL}/Jewellery/Necklaces.jpeg`,
        url: "/vintage-jewellery/necklaces"
      },
      {
        id: 49,
        title: "Pendants",
        image: `${IMAGES_FOLDER_URL}/Jewellery/Pendants.jpeg`,
        url: "/vintage-jewellery/pendants"
      },
      {
        id: 50,
        title: "Rings",
        image: `${IMAGES_FOLDER_URL}/Jewellery/Rings.jpeg`,
        url: "/vintage-jewellery/rings"
      },
      {
        id: 51,
        title: "Sets - Demi Parure",
        image: `${IMAGES_FOLDER_URL}/Jewellery/Sets - Demi Parure.jpeg`,
        url: "/vintage-jewellery/sets-demi-parure"
      },
      {
        id: 52,
        title: "Solitaire Rings",
        image: `${IMAGES_FOLDER_URL}/Jewellery/Solitaire Rings.jpeg`,
        url: "/vintage-jewellery/solitaire-rings"
      }
    ]
  },
  {
    title: "Salvage",
    url: "/antique-architectural-and-salvage",
    key: "architectural-and-salvage",
    id: 205,
    categoryImage: `${IMAGES_FOLDER_URL}/Architectural & Salvage/Architectural & Salvage.jpeg`,
    children: [
      {
        id: 53,
        title: "Baths",
        image: `${IMAGES_FOLDER_URL}/Architectural & Salvage/Baths.jpeg`,
        url: "/antique-architectural-and-salvage/baths"
      },
      {
        id: 54,
        title: "Chimneys",
        image: `${IMAGES_FOLDER_URL}/Architectural & Salvage/Chimneys.jpeg`,
        url: "/antique-architectural-and-salvage/chimneys"
      },
      {
        id: 55,
        title: "Columns",
        image: `${IMAGES_FOLDER_URL}/Architectural & Salvage/Columns.jpeg`,
        url: "/antique-architectural-and-salvage/columns"
      },
      {
        id: 56,
        title: "Door Handles / Door Stops / Door Knockers",
        image: `${IMAGES_FOLDER_URL}/Architectural & Salvage/Door+Handles+/+Door+Stops+/+Door+Knockers.jpeg`,
        url: "/antique-architectural-and-salvage/door-handles-door-stops-door-knockers"
      },
      {
        id: 57,
        title: "Doors",
        image: `${IMAGES_FOLDER_URL}/Architectural & Salvage/Doors.jpeg`,
        url: "/antique-architectural-and-salvage/doors"
      },
      {
        id: 58,
        title: "Fireplaces",
        image: `${IMAGES_FOLDER_URL}/Architectural & Salvage/Fireplaces.jpeg`,
        url: "/antique-architectural-and-salvage/fireplaces"
      },
      {
        id: 59,
        title: "Flooring",
        image: `${IMAGES_FOLDER_URL}/Architectural & Salvage/Flooring.jpeg`,
        url: "/antique-architectural-and-salvage/flooring"
      },
      {
        id: 60,
        title: "Industrial antiques",
        image: `${IMAGES_FOLDER_URL}/Architectural & Salvage/Industrial antiques.jpeg`,
        url: "/antique-architectural-and-salvage/industrial-antiques"
      },
      {
        id: 61,
        title: "Locks / Keys",
        image: `${IMAGES_FOLDER_URL}/Architectural & Salvage/Locks+/+Keys.jpeg`,
        url: "/antique-architectural-and-salvage/locks-keys"
      },
      {
        id: 62,
        title: "Reclaimed & Industrial Lighting",
        image: `${IMAGES_FOLDER_URL}/Architectural & Salvage/Reclaimed & Industrial Lighting.jpeg`,
        url: "/antique-architectural-and-salvage/reclaimed-and-industrial-lighting"
      },
      {
        id: 63,
        title: "Reclamation",
        image: `${IMAGES_FOLDER_URL}/Architectural & Salvage/Reclamation.jpeg`,
        url: "/antique-architectural-and-salvage/reclamation"
      },
      {
        id: 64,
        title: "Windows",
        image: `${IMAGES_FOLDER_URL}/Architectural & Salvage/Windows.jpeg`,
        url: "/antique-architectural-and-salvage/windows"
      },
    ]
  },
  {
    title: "Clocks",
    url: "/antique-clocks",
    key: "clocks",
    id: 206,
    categoryImage: `${IMAGES_FOLDER_URL}/Clocks/Clocks.jpg`,
    children: [
      {
        id: 65,
        title: "Bracket Clocks",
        image: `${IMAGES_FOLDER_URL}/Clocks/Bracket Clocks.jpeg`,
        url: "/antique-clocks/bracket-clocks"
      },
      {
        id: 66,
        title: "Carriage Clocks",
        image: `${IMAGES_FOLDER_URL}/Clocks/Carriage Clocks.jpeg`,
        url: "/antique-clocks/carriage-clocks"
      },
      {
        id: 67,
        title: "Clock Sets",
        image: `${IMAGES_FOLDER_URL}/Clocks/Clock Sets.jpeg`,
        url: "/antique-clocks/clock-sets"
      },
      {
        id: 68,
        title: "Cuckoo Clocks",
        image: `${IMAGES_FOLDER_URL}/Clocks/Cuckoo Clocks.jpeg`,
        url: "/antique-clocks/cuckoo-clocks"
      },
      {
        id: 688,
        title: "Dial Clocks",
        image: `${IMAGES_FOLDER_URL}/Clocks/Dial Clocks.jpeg`,
        url: "/antique-clocks/dial-clocks"
      },
      {
        id: 69,
        title: "Fusee Clocks",
        image: `${IMAGES_FOLDER_URL}/Clocks/Fusee Clocks.jpeg`,
        url: "/antique-clocks/fusee-clocks"
      },
      {
        id: 70,
        title: "Grandfather Clocks",
        image: `${IMAGES_FOLDER_URL}/Clocks/Grandfather Clocks.jpeg`,
        url: "/antique-clocks/grandfather-clocks"
      },
      {
        id: 71,
        title: "Longcase Clocks",
        image: `${IMAGES_FOLDER_URL}/Clocks/Longcase Clocks.jpeg`,
        url: "/antique-clocks/longcase-clocks"
      },
      {
        id: 72,
        title: "Mantel Clocks",
        image: `${IMAGES_FOLDER_URL}/Clocks/Mantel Clocks.jpeg`,
        url: "/antique-clocks/mantel-clocks"
      },
      {
        id: 75,
        title: "Pocket Watches",
        image: `${IMAGES_FOLDER_URL}/Watches/Pocket+Watches.jpeg`,
        url: "/vintage-watches/pocket-watches"
      },
      {
        id: 73,
        title: "Skeleton Clocks",
        image: `${IMAGES_FOLDER_URL}/Clocks/Skeleton Clocks.jpeg`,
        url: "/antique-clocks/skeleton-clocks"
      },
      {
        id: 76,
        title: "Wall Clocks",
        image: `${IMAGES_FOLDER_URL}/Clocks/Wall Clocks.jpeg`,
        url: "/antique-clocks/wall-clocks"
      },
      {
        id: 74,
        title: "Watches",
        image: `${IMAGES_FOLDER_URL}/Watches/Watches.jpeg`,
        url: "/vintage-watches"
      }
    ]
  },
  {
    title: "Mirrors",
    url: "/antique-mirrors",
    key: "mirrors",
    id: 207,
    categoryImage: `${IMAGES_FOLDER_URL}/Mirrors/Mirrors.jpg`,
    children: [
      {
        id: 77,
        title: "Convex Mirrors",
        image: `${IMAGES_FOLDER_URL}/Mirrors/Convex Mirrors.jpeg`,
        url: "/antique-mirrors/convex-mirrors"
      },
      {
        id: 78,
        title: "Dressing Mirrors",
        image: `${IMAGES_FOLDER_URL}/Mirrors/Dressing Mirrors.jpeg`,
        url: "/antique-mirrors/dressing-mirrors"
      },
      {
        id: 79,
        title: "Dressing Table Mirrors",
        image: `${IMAGES_FOLDER_URL}/Mirrors/Dressing Table Mirrors.jpeg`,
        url: "/antique-mirrors/dressing-table-mirrors"
      },
      {
        id: 80,
        title: "French Mirrors",
        image: `${IMAGES_FOLDER_URL}/Mirrors/French Mirrors.jpeg`,
        url: "/antique-mirrors/french"
      },
      {
        id: 81,
        title: "Gilt Mirrors",
        image: `${IMAGES_FOLDER_URL}/Mirrors/Gilt Mirrors.jpeg`,
        url: "/antique-mirrors/gilt"
      },
      {
        id: 82,
        title: "Large Mirrors",
        image: `${IMAGES_FOLDER_URL}/Mirrors/Large Mirrors.jpeg`,
        url: "/antique-mirrors/large-mirrors"
      },
      {
        id: 83,
        title: "Oval Mirrors",
        image: `${IMAGES_FOLDER_URL}/Mirrors/Oval Mirrors.jpeg`,
        url: "/antique-mirrors/oval-mirrors"
      },
      {
        id: 84,
        title: "Overmantle Mirrors",
        image: `${IMAGES_FOLDER_URL}/Mirrors/Overmantle Mirrors.jpeg`,
        url: "/antique-mirrors/overmantle-mirrors"
      },
      {
        id: 85,
        title: "Pier Mirrors",
        image: `${IMAGES_FOLDER_URL}/Mirrors/Pier Mirrors.jpeg`,
        url: "/antique-mirrors/pier-mirrors"
      },
      {
        id: 86,
        title: "Swing Mirrors",
        image: `${IMAGES_FOLDER_URL}/Mirrors/Swing Mirrors.jpeg`,
        url: "/antique-mirrors/swing-mirrors"
      },
      {
        id: 87,
        title: "Toilet Mirrors",
        image: `${IMAGES_FOLDER_URL}/Mirrors/Toilet Mirrors.jpeg`,
        url: "/antique-mirrors/toilet-mirrors"
      },
      {
        id: 88,
        title: "Victorian Mirrors",
        image: `${IMAGES_FOLDER_URL}/Mirrors/Victorian Mirrors.jpeg`,
        url: "/antique-mirrors/victorian-(1837-1901)"
      },
      {
        id: 89,
        title: "Wall Mirrors",
        image: `${IMAGES_FOLDER_URL}/Mirrors/Wall Mirrors.jpeg`,
        url: "/antique-mirrors/wall-mirrors"
      },
    ]
  },
  {
    title: "Chairs",
    url: "/antique-chairs",
    key: "chairs",
    id: 208,
    categoryImage: `${IMAGES_FOLDER_URL}/Chairs/Chairs.jpeg`,
    children: [
      {
        id: 879,
        title: "Armchairs",
        image: `${IMAGES_FOLDER_URL}/Chairs/Armchairs.jpeg`,
        url: "/antique-chairs/armchairs"
      },
      {
        id: 90,
        title: "Club Chairs",
        image: `${IMAGES_FOLDER_URL}/Chairs/Club Chairs.jpeg`,
        url: "/antique-chairs/club-chairs"
      },
      {
        id: 91,
        title: "Corner Chairs",
        image: `${IMAGES_FOLDER_URL}/Chairs/Corner Chairs.jpeg`,
        url: "/antique-chairs/corner-chairs"
      },
      {
        id: 92,
        title: "Country Chairs",
        image: `${IMAGES_FOLDER_URL}/Chairs/Country Chairs.jpeg`,
        url: "/antique-chairs/country-chairs"
      },
      {
        id: 93,
        title: "Desk Chairs",
        image: `${IMAGES_FOLDER_URL}/Chairs/Desk Chairs.jpeg`,
        url: "/antique-chairs/desk-chairs"
      },
      {
        id: 94,
        title: "Dining Chairs",
        image: `${IMAGES_FOLDER_URL}/Chairs/Dining Chairs.jpeg`,
        url: "/antique-chairs/dining-chairs"
      },
      {
        id: 95,
        title: "Hall Chairs",
        image: `${IMAGES_FOLDER_URL}/Chairs/Hall Chairs.jpeg`,
        url: "/antique-chairs/hall-chairs"
      },
      {
        id: 96,
        title: "Kitchen Chairs",
        image: `${IMAGES_FOLDER_URL}/Chairs/Kitchen Chairs.jpeg`,
        url: "/antique-chairs/kitchen-chairs"
      },
      {
        id: 97,
        title: "Library Chairs",
        image: `${IMAGES_FOLDER_URL}/Chairs/Library Chairs.jpeg`,
        url: "/antique-chairs/library-chairs"
      },
      {
        id: 98,
        title: "Office Chairs",
        image: `${IMAGES_FOLDER_URL}/Chairs/Office Chairs.jpeg`,
        url: "/antique-chairs/office-chairs"
      },
      {
        id: 99,
        title: "Pair of Chairs",
        image: `${IMAGES_FOLDER_URL}/Chairs/Pair of Chairs.jpeg`,
        url: "/antique-chairs/pair-of-chairs"
      },
      {
        id: 100,
        title: "Rocking Chairs",
        image: `${IMAGES_FOLDER_URL}/Chairs/Rocking Chairs.jpeg`,
        url: "/antique-chairs/rocking-chairs"
      },
      {
        id: 101,
        title: "Wing Chairs",
        image: `${IMAGES_FOLDER_URL}/Chairs/Wing Chairs.jpeg`,
        url: "/antique-chairs/wing-chairs"
      }
    ]
  },
  {
    title: "Glass",
    url: "/antique-glassware",
    key: "glassware",
    id: 209,
    categoryImage: `${IMAGES_FOLDER_URL}/Glassware/Glassware.jpg`,
    children: [
      {
        id: 1011,
        title: "Antique Drinking Glasses",
        image: `${IMAGES_FOLDER_URL}/Glassware/Antique Drinking Glasses.jpeg`,
        url: "/antique-glassware/antique-drinking-glasses"
      },
      {
        id: 102,
        title: "Champagne Glasses",
        image: `${IMAGES_FOLDER_URL}/Glassware/Champagne Glasses.jpeg`,
        url: "/antique-glassware/champagne-glasses"
      },
      {
        id: 103,
        title: "Decanters",
        image: `${IMAGES_FOLDER_URL}/Glassware/Decanters.jpeg`,
        url: "/antique-glassware/decanters"
      },
      {
        id: 104,
        title: "Epergnes",
        image: `${IMAGES_FOLDER_URL}/Glassware/Epergnes.jpeg`,
        url: "/antique-glassware/epergnes"
      },
      {
        id: 105,
        title: "Glass Bowls",
        image: `${IMAGES_FOLDER_URL}/Glassware/Glass Bowls.jpeg`,
        url: "/antique-glassware/glass-bowls"
      },
      {
        id: 106,
        title: "Glass Cruet",
        image: `${IMAGES_FOLDER_URL}/Glassware/Glass Cruet.jpeg`,
        url: "/antique-glassware/glass-cruet"
      },
      {
        id: 107,
        title: "Glass Dishes",
        image: `${IMAGES_FOLDER_URL}/Glassware/Glass Dishes.jpeg`,
        url: "/antique-glassware/glass-dishes"
      },
      {
        id: 108,
        title: "Glass Inkwell",
        image: `${IMAGES_FOLDER_URL}/Glassware/Glass Inkwell.jpeg`,
        url: "/antique-glassware/glass-inkwell"
      },
      {
        id: 109,
        title: "Glass Jugs",
        image: `${IMAGES_FOLDER_URL}/Glassware/Glass Jugs.jpeg`,
        url: "/antique-glassware/glass-jugs"
      },
      {
        id: 110,
        title: "Glass Sculptures",
        image: `${IMAGES_FOLDER_URL}/Glassware/Glass Sculptures.jpeg`,
        url: "/antique-glassware/glass-sculptures"
      },
      {
        id: 111,
        title: "Glass Vases",
        image: `${IMAGES_FOLDER_URL}/Glassware/Glass Vases.jpeg`,
        url: "/antique-glassware/glass-vases"
      },
      {
        id: 112,
        title: "Scent Bottles",
        image: `${IMAGES_FOLDER_URL}/Glassware/Scent Bottles.jpeg`,
        url: "/antique-glassware/scent-bottles"
      },
      {
        id: 113,
        title: "Tantalus",
        image: `${IMAGES_FOLDER_URL}/Glassware/Tantalus.jpeg`,
        url: "/antique-glassware/tantalus"
      },
    ]
  },
  {
    title: "Ceramics",
    url: "/antique-ceramics",
    key: "ceramics",
    id: 210,
    categoryImage: `${IMAGES_FOLDER_URL}/Ceramics/Ceramics.jpg`,
    children: [
      {
        id: 114,
        title: "Animal Figurines",
        image: `${IMAGES_FOLDER_URL}/Ceramics/Animal Figurines.jpeg`,
        url: "/antique-ceramics/animal-figurines"
      },
      {
        id: 115,
        title: "Blue and White",
        image: `${IMAGES_FOLDER_URL}/Ceramics/Blue and White.jpeg`,
        url: "/antique-ceramics/blue-and-white"
      },
      {
        id: 116,
        title: "Bowls",
        image: `${IMAGES_FOLDER_URL}/Ceramics/Bowls.jpeg`,
        url: "/antique-ceramics/bowls"
      },
      {
        id: 118,
        title: "Chargers",
        image: `${IMAGES_FOLDER_URL}/Ceramics/Chargers.jpeg`,
        url: "/antique-ceramics/chargers"
      },
      {
        id: 119,
        title: "China",
        image: `${IMAGES_FOLDER_URL}/Ceramics/China.jpeg`,
        url: "/antique-ceramics/china"
      },
      {
        id: 120,
        title: "Coffee Pots & Sets",
        image: `${IMAGES_FOLDER_URL}/Ceramics/Coffee Pots & Sets.jpeg`,
        url: "/antique-ceramics/coffee-pots-and-sets"
      },
      {
        id: 121,
        title: "Dinner Service",
        image: `${IMAGES_FOLDER_URL}/Ceramics/Dinner Service.jpeg`,
        url: "/antique-ceramics/dinner-service"
      },
      {
        id: 122,
        title: "Dishes",
        image: `${IMAGES_FOLDER_URL}/Ceramics/Dishes.jpeg`,
        url: "/antique-ceramics/dishes"
      },
      {
        id: 123,
        title: "Figurines",
        image: `${IMAGES_FOLDER_URL}/Ceramics/Figurines.jpeg`,
        url: "/antique-ceramics/figurines"
      },
      {
        id: 124,
        title: "Jugs",
        image: `${IMAGES_FOLDER_URL}/Ceramics/Jugs+/+Pitchers.jpeg`,
        url: "/antique-ceramics/jugs-pitchers"
      },
      {
        id: 125,
        title: "Tea Pots and Sets",
        image: `${IMAGES_FOLDER_URL}/Ceramics/Tea Pots and Sets.jpeg`,
        url: "/antique-ceramics/tea-pots-and-sets"
      },
      {
        id: 126,
        title: "Vases (Ceramic)",
        image: `${IMAGES_FOLDER_URL}/Ceramics/Vases (Ceramic).jpeg`,
        url: "/antique-ceramics/vases-(ceramic)"
      }
    ]
  },
  {
    title: "Directory and more",
    key: "directory-and-more",
    url: "/directory",
    isNew: true,
    id: 212,
    children: [
      {
        id: 555,
        title: "Business Directory",
        url: "/directory",
        image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/business/map-with-arrows.svg`,
        imageClassName: "top-bar-subcategory--image-directory"
      },
      {
        id: 556,
        title: "LoveAntiques Dealers",
        url: "/directory/category/dealers/love-antiques-dealers",
        image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/top-bar/dealers.png`
      },
      {
        id: 333,
        title: "Home & Living",
        url: "/home-and-living",
        image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/top-bar/h%26l.png`
      },
      {
        id: 233,
        title: "Blog",
        url: "/blog",
        image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/top-bar/blog.png`
      },
      {
        id: 234,
        title: "Materials",
        url: "/knowledge/material",
        image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/top-bar/Material.png`,
      },
      {
        id: 235,
        title: "Periods",
        url: "/period",
        image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/top-bar/Period.jpg`
      },
      {
        id: 236,
        title: "Artisans",
        url: "/knowledge/artisan",
        image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/top-bar/Artisans.png`
      },
      {
        id: 237,
        title: "Origins",
        url: "/knowledge/origin",
        image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/top-bar/Origins.png`
      },
      {
        id: 961,
        title: "Gifts For Him",
        url: "/home-and-living/for-him",
        image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/home-and-living/for-him.jpg`
      },
      {
        id: 773,
        title: "Gifts For Her",
        url: "/home-and-living/for-her",
        image: `${process.env.REACT_APP_IMAGES_URL}storefront_images/home-and-living/for-her.jpg`
      }
    ]
  },
];
