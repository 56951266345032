import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Drawer, Menu } from "antd";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

// components
import FavoritesIcon from "./FavoritesIcon";
import HeaderAuthContent from "./HeaderAuthContent";
import NotificationButton from "./NotificationButton";

// helpers
import { getAuthorizedUserProfileRequest } from "../../redux/profiles/profilesActions";

// styles
import RegistrationButtonsGroup from "./RegistrationButtonsGroup";

const MenuDrawer = ({
  isAuthenticated,
  allCategories,
  closeMobileDrawer,
  mobileMenuOpen,
  authorizedUserProfile,
  getAuthorizedUserProfile,
}) => {
  useEffect(() => {
    isAuthenticated && getAuthorizedUserProfile();
  }, [isAuthenticated]);

  const isDealer = authorizedUserProfile.role === "DEALER";

  // render
  const renderNavLink = (to, label, isSubMenu = false) => (
    <NavLink
      to={to}
      className={classNames("header--link mobile", {
        drawer: !isSubMenu,
      })}
      onClick={closeMobileDrawer}
    >
      {label}
    </NavLink>
  );

  const menuItems = [
    {
      key: "antiques",
      label: <div className="header--link drawer mobile">Categories</div>,
      children: [
        {
          key: "all-antiques",
          label: renderNavLink("/antiques", "All Antiques", true),
        },
        ...allCategories.map((category) => {
          return {
            key: category.id,
            label: (
              <>
                {renderNavLink(`/${category.url}`, category.title, true)}
                {category.itemsCount}
              </>
            ),
          };
        }),
      ],
    },
    {
      key: "home-and-living",
      label: renderNavLink("/home-and-living", "Home & Living"),
    },
    {
      key: "directory",
      label: renderNavLink(
        "/directory",
        <span>
          Directory <span className="header--link--new">NEW</span>
        </span>
      ),
    },
    // TODO: Remove NODE_ENV check once the offers page is ready
    isDealer && process.env.NODE_ENV !== "production" && {
      key: "offer-items",
      label: renderNavLink(
        "/offer-items",
        <span>
          Offered for sale <span className="header--link--new">NEW</span>
        </span>
      ),
    },
    {
      key: "blog",
      label: renderNavLink("/blog", "Blog"),
    },
  ].filter(Boolean);

  return (
    <Drawer
      className="xl-visible"
      open={mobileMenuOpen}
      onClose={closeMobileDrawer}
      placement="right"
      closable={false}
    >
      <div className="header--mobile-menu">
        <div className="header--right-block">
          <HeaderAuthContent closeMobileDrawer={closeMobileDrawer} />
        </div>

        <div className="header--mobile-menu-fav-and-notifications">
          <FavoritesIcon closeMobileDrawer={closeMobileDrawer} />

          {isAuthenticated && (
            <NotificationButton
              closeMobileDrawer={closeMobileDrawer}
              mobileMenuOpen={mobileMenuOpen}
            />
          )}
        </div>

        <Menu
          mode="inline"
          className="header--menu"
          inlineIndent={10}
          defaultOpenKeys={["antiques"]}
          items={menuItems}
        />
      </div>

      {!isAuthenticated && (
        <RegistrationButtonsGroup closeMobileDrawer={closeMobileDrawer} />
      )}
    </Drawer>
  );
};

MenuDrawer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  allCategories: PropTypes.array.isRequired,
  closeMobileDrawer: PropTypes.func.isRequired,
  mobileMenuOpen: PropTypes.bool.isRequired,
  getAuthorizedUserProfile: PropTypes.func.isRequired,
  authorizedUserProfile: PropTypes.object.isRequired,
};

export default connect(
  (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    authorizedUserProfile: state.auth.authorizedUserProfile,
    allCategories: state.home.allCategories,
  }),
  (dispatch) => ({
    getAuthorizedUserProfile: (data) =>
      dispatch(getAuthorizedUserProfileRequest(data)),
  })
)(MenuDrawer);
